<template>
  <div
    class="fixed top-0 left-0 w-full h-screen overlayMenu z-[996]"
    :class="[
      isOverlayActive ? 'pointer-events-auto animation-show-menu flex' : 'pointer-events-none block'
    ]"
  >
    <div class="inner-overlay-container">
      <div
        class="close-overlay"
        @click="toggleNavOverlay"
      >
        <SVGLoader
          class="w-6 h-6"
          name="close"
        />
      </div>
      <LanguageSwitch
        id="header-language-switch"
        class="min-w-[150px] h-[40px] lg:h-[56px] absolute lg:hidden top-4 right-4 lg:top-4 lg:right-8 z-10"
      />

      <div class="main-navigation-wrapper">
        <nav>
          <Navigation
            class="main-header"
            menuId="main-header"
          />
        </nav>
      </div>

      <div
        class="secondary-navigation-wrapper"
        :class="isOverlayActive ? '' : 'pointer-events-none'"
      >
        <LanguageSwitch
          id="header-language-switch"
          class="min-w-[150px] h-[40px] lg:h-[56px] hidden lg:block absolute top-4 right-4 lg:top-4 lg:right-8 z-10"
        />

        <div class="flex lg:flex-col 2xl:flex-row mb-6 lg:mb-11 md:gap-10">
          <nav>
            <Navigation
              class="secondary-nav"
              menuId="secondary-header"
            />
          </nav>
          <MenuAddressBlock
            class="lg:mt-auto"
            theme="light"
          />
        </div>
        <hr class="opacity-20 border-t-[0.6px] text-white" />

        <MenuContactList />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, watch, onBeforeMount, onBeforeUnmount } from 'vue';
import { useUiStore } from '~/stores/useUiStore';

const uiStore = useUiStore();
const route = useRoute();

// Computed properties
const isOverlayActive = computed(() => uiStore.ui.isOverlayActive);

// Watchers
watch(isOverlayActive, (isActive) => {
  document.documentElement.classList[isActive ? 'add' : 'remove']('no-scroll');
});

function toggleNavOverlay() {
  uiStore.toggleOverlay();
}

watch(
  route,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      nextTick(() => {
        closeOverlay();
      });
    }
  },
  { deep: true, immediate: true }
);

// Methods
const closeOverlay = () => {
  uiStore.closeOverlay();
};

const closeOverlayEsc = (e) => {
  if (isOverlayActive.value && e.keyCode === 27) {
    uiStore.closeOverlay();
  }
};

// Lifecycle hooks
onBeforeMount(() => {
  document.addEventListener('keyup', closeOverlayEsc);
});

onBeforeUnmount(() => {
  document.removeEventListener('keyup', closeOverlayEsc);
});
</script>

<style lang="postcss">
.main-header {
  @apply flex flex-wrap my-0;

  li {
    @apply flex mb-0 mr-2 mt-2 justify-center items-center;

    a {
      @apply text-white hover:text-gray-300 no-underline transition rounded-full px-3 py-2 lg:pb-1 lg:pt-2
          border-white border-[1px] border-opacity-20 uppercase font-larkenRegular h4 lg:h4 leading-none;

      &.nuxt-link-exact-active {
        @apply font-bold;
      }
    }
  }
}

.address {
  p {
    @apply paragraph-3 text-white not-italic mb-3;
    white-space: pre-line;
  }
}

.right-panel {
  a {
    @apply text-[10px];
  }
}

.overlayMenu {
  /* TODO:  */
  /* transform: translate3d(-100%, 0, 0); */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
}

.animation-show-menu {
  /* transform: translate3d(0, 0, 0); */
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s;
}

.inner-overlay-container {
  @apply flex flex-col lg:flex-row w-full h-full relative overflow-scroll lg:overflow-auto;
}

.main-navigation-wrapper {
  @apply w-full bg-green py-6;
  @apply pt-9 px-[theme(grid.gridXMargin)] lg:pt-11 lg:px-4;
  @apply lg:w-[55%] lg:pt-14 lg:h-full lg:px-8 lg:overflow-auto;
  @apply xl:w-[55%] xl:pt-11 xl:h-full xl:px-11 xl:overflow-auto;
}

.secondary-navigation-wrapper {
  @apply w-full  bg-greenDark pb-12 pt-6 lg:py-6 relative;
  @apply px-[theme(grid.gridXMargin)] lg:px-4;
  @apply lg:w-[45%] lg:flex lg:pt-14 lg:flex-col lg:h-full lg:px-8 lg:overflow-auto;
  @apply xl:w-[45%] xl:flex xl:pt-11 xl:flex-col xl:h-full xl:px-10 xl:overflow-auto;
}

.secondary-nav {
  @apply mb-0 pr-10 pl-0 list-none;

  li {
    @apply flex mb-1;
    a {
      @apply text-white no-underline paragraph-3 hover:text-gray-300;
    }

    &:last-child {
      @apply mb-0;
    }
  }
}

.close-overlay {
  @apply block lg:hidden absolute top-2 left-2 z-10 cursor-pointer;
}
</style>
