<template>
  <section
    id="detail-intro"
    class="container grid-main fade-in relative z-10"
    :class="{ 'bg-white py-8': data.backgroundColor === 'white' }"
  >
    <!-- Left column -->
    <div class="col-start-1 col-end-13 lg:col-end-8 left-img">
      <div v-if="data.slider.list.length > 1">
        <Swiper
          :slides-per-view="1"
          :loop="true"
          :modules="[SwiperPagination]"
          :pagination="{
            el: '.swiper-pagination',
            type: 'bullets'
          }"
          ref="mySwiper"
        >
          <SwiperSlide
            v-for="(slide, i) in data.slider.list"
            :key="i"
          >
            <Picture
              v-if="slide.picture?.refImage?._ref"
              :alt="slide.picture.alt"
              :isLazyloaded="false"
              :refImage="slide.picture?.refImage?._ref"
              :aspectRatios="[
                { sm: 'square' },
                { md: '16:9' },
                { lg: '16:9' },
                { xl: '16:9' },
                { '2xl': '16:9' },
                { '3xl': '16:9' }
              ]"
            />
          </SwiperSlide>
          <div class="relative w-full">
            <div class="swiper-pagination swiper-pagination-bullets m-auto bottom-0"></div>
          </div>
        </Swiper>
      </div>
      <Picture
        v-else
        :alt="data.slider.list[0].picture.alt"
        :isLazyloaded="false"
        :refImage="data.slider.list[0].picture?.refImage?._ref"
        :aspectRatios="[
          { sm: 'square' },
          { md: '16:9' },
          { lg: '16:9' },
          { xl: '16:9' },
          { '2xl': '16:9' },
          { '3xl': '16:9' }
        ]"
      />
      <div class="w-full lead mt-0 lg:mt-9 block lg:hidden">
        <SanityContent :blocks="data.leadText" />
      </div>
    </div>

    <!-- Right column -->
    <div
      class="col-start-1 lg:col-start-8 col-end-13 lg:border-l-[0.5px] border-black flex flex-col justify-between lg:sticky top-2"
    >
      <div class="mt-3 lg:mt-0">
        <template
          v-for="(text, i) in data.list"
          :key="i"
        >
          <div class="py-4 border-b-[0.5px] border-black lg:ml-4 paragraph-3 last:border-0">
            <BoldPortableText :blocks="text.infoText" />
          </div>
        </template>
      </div>
      <div class="lg:ml-3 flex gap-x-2 flex-wrap mt-2 lg:mt-0">
        <Button
          v-if="data.primaryButton && data.primaryButton.label"
          class="!w-full lg:!w-fit mt-2"
          :to="
            data.primaryButton.isExternal
              ? data.primaryButton.externalLink
              : $fullpath(data.primaryButton?.link?._ref)
          "
          :isExternal="data.primaryButton.isExternal || false"
          :target="data.primaryButton.isExternal ? '_blank' : ''"
          variant="primary"
          variantType="normal"
          >{{ data.primaryButton.label }}
        </Button>

        <Button
          v-if="data.secondaryButton && data.secondaryButton.label"
          class="!w-full lg:!w-fit mt-2"
          :to="
            data.secondaryButton?.isExternal
              ? data.secondaryButton?.externalLink
              : $fullpath(data.secondaryButton?.link?._ref)
          "
          :isExternal="data.secondaryButton?.isExternal || false"
          :target="data.secondaryButton?.isExternal ? '_blank' : ''"
          variant="secondary"
          variantType="normal"
          >{{ data.secondaryButton?.label }}
        </Button>

        <AlenoCtaButton
          v-if="data.alenoWidgetLink"
          :url="data.alenoWidgetLink"
        >
          {{ $text('book-a-table') }}
        </AlenoCtaButton>
      </div>
    </div>
    <div
      v-if="data.leadText"
      class="col-start-1 col-end-13 lg:col-end-8 lead mt-9 hidden lg:block"
    >
      <SanityContent :blocks="data.leadText" />
    </div>
  </section>
</template>

<script setup lang="ts">
import 'swiper/css/pagination';
interface detailsIntroProps {
  data: any;
}

const { data } = defineProps<detailsIntroProps>();

const { $fullpath, $text } = useSiteSettings();

// we load the script and css of aleno widget if it's defined
onMounted(() => {
  if (data.alenoWidgetLink !== '') {
    useHead({
      script: [
        {
          src: 'https://mytools.aleno.me/reservations/v2.0/reservations.js',
          async: true
        }
      ],
      link: [
        {
          rel: 'stylesheet',
          href: 'https://mytools.aleno.me/reservations/v2.0/reservations.css'
        }
      ]
    });
  }
});
</script>

<style lang="postcss">
#detail-intro {
  .left-img > div {
    @apply lg:h-full mb-3 lg:mb-0;
  }
  .swiper-pagination-bullet {
    @apply bg-white;
  }
  .swiper-pagination-bullet-active {
    @apply !bg-gray-300;
  }
  .swiper-pagination-progressbar-fill {
    @apply !bg-green;
  }
}
</style>
