<template>
  <Button
    ref="btn"
    :to="url"
    class="w-full lg:!w-fit mt-2 bottom-4 inset-x-3"
    :arrowRight="false"
    :arrowLeft="false"
    variant="primary"
    :variantType="'normal'"
  >
    <SVGLoader
      name="calendar"
      class="w-2 mr-1"
    />
    <slot />
  </Button>
  <Button
    ref="btnMobile"
    :to="url"
    class="inline-flex translate-y-full opacity-0 mt-2 bottom-4 inset-x-3 fixed"
    :arrowRight="false"
    :arrowLeft="false"
    variant="primary"
    :variantType="'normal'"
  >
    <SVGLoader
      name="calendar"
      class="w-2 mr-1"
    />
    <slot />
  </Button>
</template>

<script lang="ts" setup>
defineProps<{
  url?: string
}>()

const { $gsap } = useNuxtApp()
const btn = ref<HTMLButtonElement>()
const btnMobile = ref<HTMLButtonElement>()

onMounted(() => {
  $gsap.delayedCall(0.5, initMobileAnimation)
})

const initMobileAnimation = () => {
  const mm = $gsap.matchMedia()

  mm.add('(max-width: 1023px)', () => {
    if (!btn.value) return

    $gsap.timeline({
      scrollTrigger: {
        trigger: btn.value.$el,
        start: 'bottom top',
        end: 'top top',
        scrub: true,
        markers: false,
        toggleActions: 'play reverse play reverse',
        invalidateOnRefresh: true,
      }
    })
    .to(btnMobile.value.$el, {
      y: 0,
      opacity: 1,
      duration: 0.5,
      ease: 'back.out(1.7)'
    })
  })
}
</script>
